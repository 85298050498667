import { GoogleOAuthResponse } from '@aimmo/components/google-oauth';
import { OAuthParamNew, SocialProvider, Token } from '@aimmo/eimmo/models';
import { LANGUAGE_CODES } from '@aimmo/i18n';
import { createBroadcastChannel } from '@aimmo/utils/broadcast-channel';
import { Injectable, signal } from '@angular/core';
import { AccountInfo } from '@azure/msal-common/src/account/AccountInfo';
import { TranslateService } from '@ngx-translate/core';
import { configureScope } from '@sentry/angular-ivy';
import { RouteService } from 'aimmo-core2/app/core/route.service';
import { AuthApiService } from 'aimmo-core2/app/core/services/api/auth-api.service';
import {
  SignUpFromInvitationParam,
  SignUpParam,
  UpdateUserParam,
  UserApiService
} from 'aimmo-core2/app/core/services/api/user-api.service';
import { StoreService } from 'aimmo-core2/app/core/store.service';
import { TokenManagementService } from 'aimmo-core2/app/core/token-management.service';
import { isNotActivateSubscription, User } from 'aimmo-core2/app/shared/models/user.model';
import { defer, Observable, of, tap, throwError } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';


export interface AuthCommonAction {
  getAuthState(): Observable<AuthStateForCore>;

  getUser(): Observable<User>;

  setOAuthData(oauthData: OAuthParamNew | null): void;

  getOAuthData(): OAuthParamNew | undefined;

  getAccessToken(): Observable<string | undefined>;

  getCachedUser(): Observable<User>;

  updateCurrentUser(user: User): Observable<User>;
}

export interface AuthAzureAction {
  postMessage(message: BroadcastMessage): void;

  authAzureBroadcastMessage$(): Observable<MessageEvent>;

  authenticateForAzure(account: AccountInfo, purchaseToken: string): Observable<void>;
}

export enum AuthStateForCore {
  authorized = 'authorized',
  unauthorized = 'unauthorized',
  needSubscriptionActivate = 'need-subscription-activate'
}

export const AUTHENTICATE_AZURE_CHANNEL = 'authenticate-azure';

export enum BroadcastMessage {
  loginComplete = 'login-compete'
}

@Injectable({
  providedIn: 'root'
})
export class AuthService implements AuthCommonAction, AuthAzureAction {
  private oauthData?: OAuthParamNew | null;
  private authAzureBroadcastChannel = createBroadcastChannel(AUTHENTICATE_AZURE_CHANNEL);
  private invitationCode = signal<string | null>(null);

  constructor(
    private translate: TranslateService,
    private store: StoreService,
    private token: TokenManagementService,
    private authApi: AuthApiService,
    private userApi: UserApiService,
    private routeService: RouteService,
  ) {
  }

  public authAzureBroadcastMessage$(): Observable<MessageEvent> {
    return this.authAzureBroadcastChannel.onmessage$();
  }

  public postMessage(message: BroadcastMessage): void {
    this.authAzureBroadcastChannel.postMessage(message);
  }

  public setOAuthData(oauthData: OAuthParamNew | null): void {
    this.oauthData = oauthData;
  }

  public getOAuthData(): OAuthParamNew | undefined {
    return this.oauthData ?? undefined;
  }

  public setInvitationCode(code: string): void {
    this.invitationCode.set(code);
  }

  public getInvitationCode(): string | null {
    return this.invitationCode();
  }

  public getAccessToken(): Observable<string | undefined> {
    return this.token.getAccessToken();
  }

  public getAuthState(): Observable<AuthStateForCore> {
    return this.getAccessToken().pipe(
      switchMap(accessToken => {
        const hasAccessToken = !!accessToken;
        return hasAccessToken ? this.getCachedUser() : throwError(() => 'not authorized');
      }),
      map(user => {
        if (isNotActivateSubscription(user)) {
          return AuthStateForCore.needSubscriptionActivate;
        } else {
          return AuthStateForCore.authorized;
        }
      }),
      catchError(() => of(AuthStateForCore.unauthorized))
    );
  }


  public getCachedUser(): Observable<User> {
    const currentUser = this.store.currentUser();
    return currentUser ? of(currentUser) : this.getUser();
  }

  public getUser(): Observable<User> {
    return this.userApi.me().pipe(
      switchMap(user => this.updateCurrentUser(user))
    );
  }

  public updateCurrentUser(user: User): Observable<User> {
    return of(user).pipe(
      tap(user => this.store.setCurrentUser(user)),
      tap(user => configureScope(scope => scope.setUser(user?.email ? { email: user.email } : null))),
      switchMap(user => this.setLanguage(user))
    );
  }

  public updateUser(param: UpdateUserParam): Observable<User> {
    return this.userApi.update(param);
  }

  public authenticateByGoogleForCore(data: GoogleOAuthResponse): Observable<void> {
    const oauthData: OAuthParamNew = {
      provider: SocialProvider.google,
      idToken: {
        token: data.credential,
        clientId: data.clientId
      }
    };
    this.setOAuthData(oauthData);
    return this.authApi.authenticate({ idToken: data.credential }).pipe(
      tap(() => this.setOAuthData(null)),
      switchMap(token => this.setTokenAll(token))
    );
  }

  public authenticateForAzure(account: AccountInfo, purchaseToken: string): Observable<void> {
    const oauthData: OAuthParamNew = {
      provider: SocialProvider.azure,
      azureIdToken: account.idToken
    };
    this.setOAuthData(oauthData);
    return this.authApi.authenticate({ idToken: account.idToken, purchaseToken }).pipe(
      tap(() => this.setOAuthData(null)),
      switchMap(token => this.setTokenAll(token))
    );
  }

  public authenticateByInvitation(data: GoogleOAuthResponse, code: string): Observable<void> {
    const oauthData: OAuthParamNew = {
      provider: SocialProvider.google,
      idToken: {
        token: data.credential,
        clientId: data.clientId
      }
    };
    this.setOAuthData(oauthData);
    this.setInvitationCode(code); // 음..
    return this.authApi.authenticateByInvitation({ idToken: data.credential, code }).pipe(
      tap(() => this.setOAuthData(null)),
      switchMap(token => this.setTokenAll(token))
    );
  }


  public signUp(param: SignUpParam): Observable<void> {
    return this.userApi.signUp(param).pipe(
      tap(() => this.setOAuthData(null)),
      switchMap(token => this.setTokenAll(token))
    );
  }

  public signUpFromInvitation(param: SignUpFromInvitationParam): Observable<void> {
    return this.userApi.signUpFromInvitation(param).pipe(
      tap(() => {
        this.setOAuthData(null);
        this.setInvitationCode(null);
      }),
      switchMap(token => this.setTokenAll(token))
    );
  }

  public logout(): Observable<void> {
    return of(true).pipe(
      catchError(() => of(null)),
      tap(() => this.store.setCurrentUser(null)),
      switchMap(() => this.token.clearTokenAll()),
      tap(() => this.routeService.routeToAimmoCoreHomepage())
    );
  }

  private setTokenAll(token: Token): Observable<void> {
    return this.token.setTokenAll(token.accessToken, token.refreshToken);
  }

  private setLanguage(user: User): Observable<User> {
    return defer(() => {
      const languageCode = user.lang;
      if (languageCode && LANGUAGE_CODES.includes(languageCode)) {
        return this.translate.use(languageCode).pipe(
          map(() => user),
          catchError(() => of(user))
        );
      }
      return of(user);
    });
  }
}
