import { Observable } from 'rxjs';

export interface IBroadcastChannel {
  channel: BroadcastChannel;
  postMessage: (message: any) => void;
  onmessage$: () => Observable<MessageEvent>;
  close: () => void;
}

export function createBroadcastChannel(channelName: string): IBroadcastChannel {
  const broadcastChannel = new BroadcastChannel(channelName);
  return {
    channel: broadcastChannel,
    postMessage: (message: any) => broadcastChannel.postMessage(message),
    onmessage$: () => new Observable(observer => {
      broadcastChannel.onmessage = (message) => observer.next(message);
    }),
    close: () => broadcastChannel.close(),
  };
}
