import { RefreshParam } from '@aimmo/eimmo/models';
import { RxNgForage } from '@aimmo/services/ngforage';
import { Injectable } from '@angular/core';
import { isAimmoCoreAzurePhase } from 'aimmo-core2/app/shared/util/env-util';
import { defer, forkJoin, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export const ACCESS_TOKEN = isAimmoCoreAzurePhase()
  ? 'AZURE_AIMMO_CORE_ACCESS_TOKEN'
  : 'AIMMO_CORE_ACCESS_TOKEN';
export const REFRESH_TOKEN = isAimmoCoreAzurePhase()
  ? 'AZURE_AIMMO_CORE_REFRESH_TOKEN'
  : 'AIMMO_CORE_REFRESH_TOKEN';

@Injectable({
  providedIn: 'root'
})
export class TokenManagementService {

  constructor(
    private ngForage: RxNgForage
  ) {
  }

  public setTokenAll(accessToken: string, refreshToken: string): Observable<void> {
    return forkJoin([
      this.setAccessToken(accessToken),
      this.setRefreshToken(refreshToken)
    ]).pipe(map(() => undefined));
  }

  public getTokenAll(): Observable<RefreshParam> {
    return forkJoin([
      this.getAccessToken(),
      this.getRefreshToken()
    ]).pipe(
      map(([accessToken, refreshToken]) => ({ accessToken, refreshToken }))
    );
  }

  public setAccessToken(token: string): Observable<string> {
    return this.setToken(ACCESS_TOKEN, token);
  }

  public setRefreshToken(token: string): Observable<string> {
    return this.setToken(REFRESH_TOKEN, token);
  }

  public getAccessToken(): Observable<string | undefined> {
    return this.getToken(ACCESS_TOKEN);
  }

  public getRefreshToken(): Observable<string | undefined> {
    return this.getToken(REFRESH_TOKEN);
  }

  public clearTokenAll(): Observable<void> {
    return forkJoin([
      this.clearAccessToken(),
      this.clearRefreshToken()
    ]).pipe(map(() => undefined));
  }

  public clearAccessToken(): Observable<void> {
    return this.clearToken(ACCESS_TOKEN);
  }

  public clearRefreshToken(): Observable<void> {
    return this.clearToken(REFRESH_TOKEN);
  }

  private setToken(tokenKey: string, token: string): Observable<string> {
    return this.ngForage.setItem<string>(tokenKey, token);
  }

  private getToken(tokenKey: string): Observable<string | undefined> {
    return defer(() => this.ngForage.getItem<string>(tokenKey));
  }

  private clearToken(tokenKey: string): Observable<void> {
    return defer(() => this.ngForage.removeItem(tokenKey));
  }
}
