import { Lang } from '@aimmo/i18n';

export interface User {
  userId: string;
  name: string;
  email: string;
  lang: Lang;
  subscriptionId: string;
  subscriptionStatus: SubscriptionStatus;
  notification: boolean;
}

export enum SubscriptionStatus {
  pendingFulfillmentStart = 'PendingFulfillmentStart',
  subscribe = 'Subscribe',
  suspended = 'Suspended',
  unsubscribed = 'Unsubscribed',
}

export function isNotActivateSubscription(user: User): boolean {
  return user.subscriptionStatus === SubscriptionStatus.pendingFulfillmentStart;
}
