import { Token } from '@aimmo/eimmo/models';
import { Lang } from '@aimmo/i18n';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { User } from 'aimmo-core2/app/shared/models/user.model';
import { Observable } from 'rxjs';

export interface UserLanguageParam {
  lang: Lang;
}

export interface SignUpParam {
  agreements: {
    privacyPolicy: boolean;
    terms: boolean;
    software: boolean;
  };
  idToken: string;
}

export type SignUpFromInvitationParam = SignUpParam & { code: string };

export interface UpdateUserParam {
  name: string;
  lang: string;
  notification: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class UserApiService {
  private httpClient = inject(HttpClient);

  public me(): Observable<User> {
    return this.httpClient.get<User>('/user/me');
  }

  public language(param: UserLanguageParam): Observable<User> {
    return this.httpClient.post<User>('/user/language', param);
  }

  public signUp(param: SignUpParam): Observable<Token> {
    return this.httpClient.post<Token>('/user/signup', param);
  }

  public signUpFromInvitation(param: SignUpFromInvitationParam): Observable<Token> {
    return this.httpClient.post<Token>('/user/invitation/signup', param);
  }

  public update(param: UpdateUserParam): Observable<User> {
    return this.httpClient.post<User>('/user/me', param);
  }
}
