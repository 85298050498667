import { Token } from '@aimmo/eimmo/models';
import { HttpClient } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';

export interface AuthenticateAzureParam {
  purchaseToken: string;
  idToken: string;
}

export interface AuthenticateCoreParam {
  idToken: string;
}

export interface AuthenticateByInvitationParam {
  code: string;
  idToken: string;
}

@Injectable({
  providedIn: 'root'
})
export class AuthApiService {
  private httpClient = inject(HttpClient);

  public authenticate(param: AuthenticateCoreParam | AuthenticateAzureParam): Observable<Token> {
    return this.httpClient.get<Token>('/auth/authenticate', { params: param as any });
  }

  public authenticateByInvitation(param: AuthenticateByInvitationParam): Observable<Token> {
    return this.httpClient.post<Token>('/auth/invitation/authenticate', param);
  }
}
